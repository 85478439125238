import { Modal } from 'bootstrap';

// Función modal authent
window.showAuthModal = function() {
  // console.log('Mostrar modal de autenticación');
  var authModalElement = document.getElementById('authModal');
  
  if (authModalElement) {
    var myAuthModal = new Modal(authModalElement, {
      keyboard: false
    });
    myAuthModal.show();
  }
};

document.addEventListener("turbolinks:load", function() {
  // Seleccionar la etiqueta <section> con data-user-signed-in
  let sectionElement = document.querySelector('section[data-user-signed-in]');
  let userSignedIn = sectionElement && sectionElement.dataset.userSignedIn === "true";
  console.log('User signed in:', userSignedIn);

  // mostrar el modal si !account_sign_in?
  if (!userSignedIn) {
    showAuthModal();
  }
});

 