// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import moment from 'moment'
window.moment = moment



global.$ = require('jquery')
import 'packs/popovers'
import "packs/dashboard"
import "packs/jsHome"
import 'bootstrap'
import 'css/styles'
import 'css/login'
import 'css/form'
import 'css/spinner'
import 'selectize'
import 'css/home-page.scss'
import 'packs/validator'
import 'packs/form_store'
import "@fortawesome/fontawesome-free/css/all"
import 'packs/spinner'
import 'packs/sticky_section'
import 'css/date-range-picker'
import 'packs/location'
import 'packs/shopping_cart'
import 'packs/time_zone'
import 'packs/modal_session'
// import 'packs/map_static'
// import 'packs/search'
import 'controllers'
// import '@googlemaps/markerclusterer'
// import'packs/filter'
// import'packs/reload'
import'css/dashboard'
import'packs/preview_img'
import'packs/modal'
import'packs/dateRange'
import "chartkick/chart.js"
// import 'packs/sticky_sidebar'


global.toastr = require('toastr')

// import'packs/get_stores'

window.initMap = function(...args) {
    const event = document.createEvent("Events")
    event.initEvent("google-maps-callback", true, true)
    event.args = args
    window.dispatchEvent(event)
}



Rails.start()
Turbolinks.start()
ActiveStorage.start()


require("trix")
require("@rails/actiontext")
