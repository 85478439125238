import { Html5Qrcode } from 'html5-qrcode';

document.addEventListener("turbolinks:load", function() {
    
    const startScanButton = document.getElementById('start-scan');
    const statusCamera = document.getElementById('status-camera')
    
    if (startScanButton) {
        const storeId = startScanButton.getAttribute('data-store-id');
        let html5QrCode; // Variable para mantener la instancia del escáner

        startScanButton.addEventListener('click', function() {
            const namePartner = document.getElementById('status-camera').getAttribute('data-partner-name');

            if (!html5QrCode) {
                html5QrCode = new Html5Qrcode("qr-reader");
            }

            html5QrCode.start(
                { facingMode: "environment" },
                { fps: 10, qrbox: 250 },
                decodedText => {
                    
                    html5QrCode.stop().then(() => {
                        onScanSuccess(decodedText, storeId); // Procesa el texto decodificado
                    }).catch(err => {
                        console.error("Error al detener el escáner", err);
                    });
                },
                errorMessage => {
                    // Manejo de errores de escaneo
                }
            ).catch(err => {
                console.error("Error al iniciar el escáner", err);

                statusCamera.innerHTML = `
                <div class="alert alert-warning alert-dismissible fade show" role="alert">
                    <strong>${namePartner}</strong>, no hemos encontrado ningun dispositivo para escanear 
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>`

                
            });
            
        });
    }

    function onScanSuccess(decodedText, storeId) {
        console.log("Decoded Text:", decodedText); // Imprimir el texto decodificado

        let qrData;
        try {
            qrData = JSON.parse(decodedText); // parsear el string JSON
        } catch (e) {
            console.error("Error al parsear el texto decodificado:", e);
            
            return;
        }
        
        $.ajax({
            url: '/stores/' + storeId + '/validator',
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({ qr_code: qrData }), // Envía qrData como un objeto
            dataType: 'json',
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                if (response.success) {
                    // Agrega el nuevo QR validado al principio de la tabla
                    $("#qr-codes-table").html(response.html);
                    $('#detalles-pedido').removeClass('d-none alert-danger').addClass('d-block alert-success').html('Orden Validada 🤘🏼');
                } else {
                    $('#detalles-pedido').removeClass('d-none alert-success').addClass('d-block alert-danger').html('Error: ' + (response.error || 'Error desconocido'));
                }
                console.log("Respuesta del servidor:", response);
            },
            
            error: function(xhr, status, error) {
                console.log("Error en la solicitud AJAX:", xhr, status, error);
                $('#detalles-pedido').html('Error: ' + error);
                
            }
        });
    }

    const form = document.querySelector('.qr_code'); 
    const input = document.querySelector('input[name="qr_code[order_number]"]');
    const button = document.querySelector('.btn')

    if (form) {
        form.addEventListener('submit', () => {
            const trimmedValue = input.value.trim();
            input.value = trimmedValue; // Actualiza el valor del input con el valor recortado
            
        });    
    }
    
  
    const orderForm = document.querySelector('form[action*="validator"]');
    if (orderForm) {
        orderForm.addEventListener('ajax:success', function(event) {
            const [data, status, xhr] = event.detail;
            console.log('Respuesta del formulario:', data);

            if (data.success) {
                $("#qr-codes-table").html(data.html);
                $('#detalles-pedido').removeClass('d-none alert-danger').addClass('d-block alert-success').html('Orden Validada');
            } else {
                $('#detalles-pedido').removeClass('d-none alert-success').addClass('d-block alert-danger').html('Error: ' + (data.error || 'Error desconocido'));
            }
        });
    }

    document.body.addEventListener('click', () => {
        if (statusCamera) {
            statusCamera.innerHTML = ''
            // document.querySelector('#detalles-pedido').classList.remove('alert-danger') 
            // document.querySelector('#detalles-pedido').innerHTML = '' PENDIND
        }
    })

   
});
