import { Modal } from 'bootstrap';

window.showModal = function() {
  var myModal = new Modal(document.getElementById('qrCodeModal'), {
    keyboard: false
  });
  myModal.show();
};

document.addEventListener('DOMContentLoaded', function() {
  console.log('DOMContentLoaded del modal');
  document.addEventListener('qrCodeGenerated', function() {
    showModal();
  });
  
});
